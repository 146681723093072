import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { Button } from "reactstrap";
import { ModalAgregarEvento } from "../Modals/ModalAgregarEvento";
import { ModalEditarEvento } from "../Modals/ModarEditarEvento";
import { ModalEditarImagenes } from "../Modals/ModalImages";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { validateToken } from "../../../components/validateToken";

export const EventosSocios = () => {
  const [datosEventos, setDatosEventos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [infoEdit, setInfoEdit] = useState(null);
  const [infoImages, setInfoImages] = useState(null);
  const [modalDetalle, setModalDetalle] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalImagen, setModalImagen] = useState(false);
  const [modalAgregar, setModalAgregar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchIdEvento, setSearchIdEvento] = useState("");
  const [searchFechaFin, setSearchFechaFin] = useState("");
  const [searchVendedor, setSearchVendedor] = useState("");
  const [searchFechaInicio, setSearchFechaInicio] = useState("");
  const [searchFechaFinFiltro, setSearchFechaFinFiltro] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(100);
  const [totalEvents, setTotalEvents] = useState(0);
  const [count, setCount] = useState(0);
  const [totalMonto, setTotalMonto] = useState(0);
  const [totalEsperado, setTotalEsperado] = useState(0);
  const [userName, setUserName] = useState("");
  const [admins, setAdmins] = useState([]);

  const toggleModalAgregar = () => setModalAgregar(true);
  const toggleModalDetalle = () => setModalDetalle(!modalDetalle);
  const toggleModalEditar = (e) => {
    setInfoEdit(e);
    setModalEditar(true);
  };

  const toggleModalImages = async (id) => {
    const response = await axios.get(`/api/Images/FromEvent/${id}`);
    if (response.status != 200) {
      alert("Could not fetch image information for this event");
      return;
    }

    setInfoImages(response.data);
    setModalImagen(true);
  };

  const fetchData = async (queryParams) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/Eventos/filtradoSocios`, {
        params: queryParams,
      });
      console.log(response.data);
      setDatosEventos(response.data);
      setTotalEvents(parseInt(response.headers["x-total-count"], 10));
      setCount(response.data.length);

      const totalMonto = response.data.reduce(
        (acc, evento) => acc + parseFloat(evento.monto || 0),
        0
      );
      setTotalMonto(totalMonto);

      const totalEsperado = response.data.reduce(
        (acc, evento) => acc + parseFloat(evento.total || 0),
        0
      );

      setTotalEsperado(totalEsperado);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Error");
    }
  };

  const debounceFetchData = useCallback(
    debounce((queryParams) => fetchData(queryParams), 1500),
    []
  );

  const resetForm = async (idEvento, tipoEvento) => {
    const confirmed = window.confirm(
      "Deseas Resetear la Información de la Invitación " + idEvento + " ?"
    );

    if (confirmed) {
      try {
        toast.loading(`Reseteando Formulario ${idEvento}`);
        if (tipoEvento === "Boda") {
          await axios.delete(`/api/Eventos/boda/${idEvento}`);
        } else {
          await axios.delete(`/api/Eventos/${idEvento}`);
        }
        debounceFetchData({
          nombre: searchTerm,
          idEvento: searchIdEvento,
          fechaFin: searchFechaFin,
          fechaInicio: searchFechaInicio,
          fechaFinFiltro: searchFechaFinFiltro,
          vendedor: searchVendedor,
          pageNumber: pageNumber,
          pageSize: pageSize,
        });
        toast.dismiss();
        toast.success("Se ha Reseteado");
      } catch (error) {
        console.error("Error al eliminar el invitado:", error);
        toast.dismiss();
        toast.error("Error...");
      }
    } else {
      console.log("Eliminación cancelada");
    }
  };

  const fetchAdmins = async () => {
    try {
      const responseAdmin = await axios.get(`/api/Admins`);
      console.log(responseAdmin.data);
      setAdmins(responseAdmin.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    debounceFetchData({
      nombre: searchTerm,
      idEvento: searchIdEvento,
      fechaFin: searchFechaFin,
      fechaInicio: searchFechaInicio,
      fechaFinFiltro: searchFechaFinFiltro,
      vendedor: searchVendedor,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
  }, [
    searchTerm,
    searchIdEvento,
    searchFechaFin,
    searchFechaInicio,
    searchFechaFinFiltro,
    searchVendedor,
    pageNumber,
    pageSize,
    debounceFetchData,
    modalEditar,
    modalAgregar,
  ]);

  useEffect(() => {
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);
    };
    fetchAdmins();
    validateAndFetchData();
  }, []);

  const handleNextPage = () => {
    if ((pageNumber + 1) * pageSize < totalEvents) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  const handlePreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div className="overflow-x-auto p-10">
      {loading ? (
        <div>Cargando...</div>
      ) : (
        <div className="w-full">
          {userName && (
            <h1 className="p-2 text-center font-medium">
              EXCELENTE DÍA 🫶 <br />
              {userName}
            </h1>
          )}

          <br />
          <button
            className="bg-green-700 text-white p-2 text-2xl rounded-xl"
            onClick={toggleModalAgregar}
          >
            Agregar Evento
          </button>

          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
            <input
              type="text"
              placeholder="Buscar por nombre..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Filtrar por ID de Evento..."
              value={searchIdEvento}
              onChange={(e) => setSearchIdEvento(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              value={searchFechaFin}
              onChange={(e) => setSearchFechaFin(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Estatus...</option>
              <option value="Nada">Nada</option>
              <option value="Formulario">Formulario</option>
              <option value="Entregado">Entregado</option>
              <option value="Formateado">Formateado</option>
              <option value="Test">Test</option>
              <option value="Ejemplos">Ejemplos</option>
            </select>
            <input
              type="date"
              placeholder="Filtrar por Fecha de Inicio..."
              value={searchFechaInicio}
              onChange={(e) => setSearchFechaInicio(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              value={searchVendedor}
              onChange={(e) => setSearchVendedor(e.target.value)}
              className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Filtrar por Vendedor</option>
              {Array.isArray(admins) && admins.length > 0 ? (
                admins.map((item) => (
                  <option key={item.nombre} value={item.nombre}>
                    {item.nombre}
                  </option>
                ))
              ) : (
                <h1>Cargando...</h1>
              )}
            </select>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
            <p className="py-2 px-4">Total de Eventos: {count}</p>
            <p className="py-2 px-4">
              Suma Total de Monto: {totalMonto.toFixed(2)}
            </p>
            <p className="py-2 px-4">
              Suma Total Esperado: {totalEsperado.toFixed(2)}
            </p>
          </div>

          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-2 py-2 border-b text-xs">Id</th>
                <th className="px-2 py-2 border-b text-xs">Nombre</th>
                <th className="px-2 py-2 border-b text-xs">Nombre del Comprador</th>
                <th className="px-2 py-2 border-b text-xs">Número del Comprador</th>
                <th className="px-2 py-2 border-b text-xs">Tipo de Evento</th>
                <th className="px-2 py-2 border-b text-xs">Paquete</th>
                <th className="px-2 py-2 border-b text-xs">Fecha de Inicio</th>
                <th className="px-2 py-2 border-b text-xs">Monto</th>
                <th className="px-2 py-2 border-b text-xs">Estatus</th>
                <th className="px-2 py-2 border-b text-xs">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {datosEventos.map((evento) => (
                <tr key={evento.idEvento} className="hover:bg-gray-50">
                  <td className="px-2 py-2 border-b text-xs">{evento.idEvento}</td>
                  <td className="px-2 py-2 border-b text-xs">{evento.nombre}</td>
                  <td className="px-2 py-2 border-b text-xs">{evento.nombreComprador}</td>
                  <td className="px-2 py-2 border-b text-xs">{evento.numComprador}</td>
                  <td className="px-2 py-2 border-b text-xs">{evento.tipoEvento}</td>
                  <td className="px-2 py-2 border-b text-xs">{evento.paquete}</td>
                  <td className="px-2 py-2 border-b text-xs">
                    {evento.fechaInicio}
                    <br />
                    {evento.vendedor}
                  </td>
                  <td className="px-2 py-2 border-b text-xs">
                    ${evento.monto} / ${evento.total}
                  </td>
                  <td className="px-2 py-2 text-xs">
                    {evento.detalles && evento.detalles.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap text-xs">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${evento.fechaFin === "Nada"
                        ? "bg-red-100 text-red-800"
                        : evento.fechaFin === "Formulario"
                          ? "bg-blue-100 text-blue-800"
                          : evento.fechaFin === "Formateado"
                            ? "bg-purple-100 text-purple-800"
                            : evento.fechaFin === "Test"
                              ? "bg-yellow-100 text-yellow-800"
                              : evento.fechaFin === "Ejemplos"
                                ? "bg-yellow-100 text-yellow-800"
                                : evento.fechaFin === "Entregado"
                                  ? "bg-green-100 text-green-800"
                                  : ""
                        }`}
                    >
                      {evento.fechaFin}
                    </span>
                  </td>
                  <td className="px-2 py-2 border-b text-xs">
                    <a
                      className="bg-blue-400 m-1 p-1"
                      color="info"
                      href={
                        evento.tipoEvento === "Boda"
                          ? `/admin-boda-detalles?id=${evento.idEvento}`
                          : `/admin-xv-detalles?id=${evento.idEvento}`
                      }
                    >
                      🔎
                    </a>{" "}
                    {evento.paquete === "Diamante" ||
                      evento.enableInvitados === true ? (
                      ""
                    ) : (
                      <a
                        className="bg-blue-400 m-1 p-1"
                        color="info"
                        href={
                          evento.tipoEvento === "Boda"
                            ? evento.ingles
                              ? `/en/wedding-auto?id=${evento.idEvento}`
                              : `/boda-auto?id=${evento.idEvento}`
                            : evento.ingles
                              ? `/en/event-auto?id=${evento.idEvento}`
                              : `/xv-auto?id=${evento.idEvento}`
                        }
                      >
                        👀
                      </a>
                    )}
                    <a
                      className="bg-blue-400 m-1 p-1"
                      color="info"
                      href={
                        evento.tipoEvento === "Boda"
                          ? evento.ingles
                            ? `/form-wedding?id=${evento.idEvento}`
                            : `/formulario-boda?id=${evento.idEvento}`
                          : evento.ingles
                            ? `/form-event?id=${evento.idEvento}`
                            : `/formulario-xv?id=${evento.idEvento}`
                      }
                    >
                      📨
                    </a>{" "}
                    {evento.paquete === "Diamante" ||
                      evento.enableInvitados === true ? (
                      <a
                        className="bg-blue-400 m-1 p-1"
                        color="info"
                        href={
                          evento.ingles
                            ? `/guests-generate?id=${evento.idEvento}`
                            : `/invitados-generate?id=${evento.idEvento}`
                        }
                      >
                        😎
                      </a>
                    ) : (
                      ""
                    )}{" "}
                    <Button
                      className="bg-blue-400 m-1 p-1"
                      onClick={() => {
                        toggleModalEditar(evento);
                      }}
                    >
                      ✎
                    </Button>{" "}
                    <Button
                      className="bg-blue-400 m-1 p-1"
                      onClick={() => {
                        resetForm(evento.idEvento, evento.tipoEvento);
                      }}
                    >
                      🔄
                    </Button>
                    <Button
                      className="bg-blue-400 m-1 p-1"
                      onClick={() => {
                        toggleModalImages(evento.idEvento);
                      }}
                    >
                      🖼️
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-between mt-4">
            <button
              disabled={pageNumber === 0}
              onClick={handlePreviousPage}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Anterior
            </button>
            <button
              disabled={(pageNumber + 1) * pageSize >= totalEvents}
              onClick={handleNextPage}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Siguiente
            </button>
          </div>
          <div className="w-full flex justify-center mt-2 items-center p-2">
            <button
              className="bg-red-700 text-white p-2 text-xl rounded-xl float-right"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}

      <ModalAgregarEvento
        modalAgregar={modalAgregar}
        setModalAgregar={setModalAgregar}
        admin={userName}
        socio={"Rafa"}
      />

      {modalEditar && (
        <ModalEditarEvento
          modalEditar={modalEditar}
          setModalEditar={setModalEditar}
          infoEdit={infoEdit}
        />
      )}

      {modalImagen && (
        <ModalEditarImagenes
          modalImages={modalImagen}
          setModalImages={setModalImagen}
          infoImages={infoImages}
        />
      )}
    </div>
  );
};
