import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function BirthdayInvitation({ datosSeccionPotada }) {
  useEffect(() => {
    console.log(datosSeccionPotada)
    AOS.init({ duration: 1500 })
  }, [])

  const addToGoogleCalendar = () => {
    const event = {
      text: `${datosSeccionPotada?.nombre || "DIEGO"} - ${datosSeccionPotada?.titulo || "5º Cumpleaños"}`,
      dates: '20240218T153000/20240218T203000', // Formato YYYYMMDDTHHMMSS
      details: datosSeccionPotada?.textoConfirmacion || 'Celebración del 5º cumpleaños',
      location: 'Ubicación del evento'
    };

    const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.text)}&dates=${event.dates}&details=${encodeURIComponent(event.details)}&location=${encodeURIComponent(event.location)}`;
    window.open(url, '_blank');
  };

  const fechaPartes = (datosSeccionPotada?.fechaDeBoda || "31 OCTUBRE, 2027").split(" ");
  const dia = fechaPartes[0];
  const mes = fechaPartes[1];

  // Formatear la hora de recepción y obtener día de la semana
  const formatearHoraYDia = (horaStr) => {
    if (!horaStr) return { hora: "3:30 PM", diaSemana: "SÁBADO" };
    
    const fecha = new Date(horaStr);
    const hora = fecha.toLocaleTimeString('es-ES', { 
      hour: 'numeric',
      minute: '2-digit',
      hour12: true 
    }).toUpperCase();

    const diasSemana = ['DOMINGO', 'LUNES', 'MARTES', 'MIÉRCOLES', 'JUEVES', 'VIERNES', 'SÁBADO'];
    const diaSemana = diasSemana[fecha.getDay()];

    return { hora, diaSemana };
  };

  const { hora: horaFormateada, diaSemana } = formatearHoraYDia(datosSeccionPotada?.recepcionHora);

  return (
    <div className="w-screen h-screen bg-gradient-to-br from-blue-900 to-purple-900 p-0 flex items-center justify-center">
      <div data-aos="zoom-in" className="relative w-full h-full max-w-md p-0 bg-gradient-to-b from-cyan-400 to-blue-600 overflow-hidden shadow-2xl">
        <div className="absolute inset-2 bg-black overflow-hidden">
          <div className="relative h-full w-full">
            {/* Background image */}
            <div 
              className="absolute inset-0 bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${datosSeccionPotada?.imgPortada || 'https://e1.pxfuel.com/desktop-wallpaper/258/10/desktop-wallpaper-4-spiderman-for-kids-room-kid-spider-man.jpg'})`,
                opacity: 0.8
              }}
            />

            {/* Falling stars overlay */}
            <div className="absolute inset-0">
              {[...Array(20)].map((_, i) => (
                <div
                  key={i}
                  className="absolute w-1 h-1 bg-white rounded-full"
                  style={{
                    left: `${Math.random() * 100}%`,
                    animation: `fallingStar ${5 + Math.random() * 10}s linear infinite`,
                    animationDelay: `${Math.random() * 5}s`
                  }}
                />
              ))}
            </div>

            {/* Overlay gradient */}
            <div className="absolute inset-0 ">
              <div className="absolute inset-0 opacity-30">
                <div data-aos="slide-right" className="h-1/3 w-full transform -skew-y-12"></div>
                <div data-aos="slide-left" className="h-1/3 w-full transform skew-y-12"></div>
                <div data-aos="slide-right" className="h-1/3 w-full transform -skew-y-12"></div>
              </div>
            </div>

            {/* Content */}
            <div className="relative h-full flex flex-col justify-between p-6 text-white" style={{ color: datosSeccionPotada?.textColor || 'white', fontFamily: datosSeccionPotada?.tipoLetra }}>
              <div data-aos="fade-down" className="text-center mt-8">
                <h1 className="text-5xl font-bold mb-2 drop-shadow-lg animate-pulse">{datosSeccionPotada?.nombre || "DIEGO"}</h1>
                <p className="text-lg mb-4">{datosSeccionPotada?.textoConfirmacion || "ACOMPÁÑANOS A CELEBRAR ESTE DÍA TAN ESPECIAL"}</p>
              </div>

              <div data-aos="fade-up" className="text-center mb-4">
                <div className="flex justify-center items-center gap-8 mt-8">
                  <span className="text-7xl font-bold">{dia}</span>
                  <div className="text-left">
                    <span className="text-2xl font-semibold">{mes}</span>
                    <br />
                    <span className="text-xl">{diaSemana}</span>
                    <br />
                    <span className="text-xl">{horaFormateada}</span>
                  </div>
                </div>
                <p className="text-3xl font-bold mt-4">{datosSeccionPotada?.titulo || "5º CUMPLEAÑOS"}</p>
                <p className="text-2xl font-bold mt-2 animate-bounce">¡TE ESPERAMOS!</p>
              </div>
              <div data-aos="fade-up" className="space-y-4 px-4">
                <div className="grid grid-cols-2 gap-3 mb-3">
                  <button className="group py-3 px-4 bg-opacity-20 bg-[#000000] backdrop-blur-sm border border-white/30 rounded-lg text-base font-medium hover:bg-opacity-30 transition-all duration-500 flex items-center justify-center overflow-hidden relative">
                    <span className="absolute inset-0 bg-gradient-to-r from-cyan-300/10 to-cyan-500/10 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                    <img 
                      src="https://cdn-icons-png.flaticon.com/512/1865/1865269.png" 
                      alt="location" 
                      className="w-5 h-5 mr-2 opacity-80 group-hover:opacity-100 transition-opacity"
                    />
                    <span className="relative z-10">Ubicación</span>
                  </button>

                  <button 
                    onClick={addToGoogleCalendar}
                    className="group py-3 px-4 bg-opacity-20 bg-[#000000] backdrop-blur-sm border border-white/30 rounded-lg text-base font-medium hover:bg-opacity-30 transition-all duration-500 flex items-center justify-center overflow-hidden relative"
                  >
                    <span className="absolute inset-0 bg-gradient-to-r from-red-300/10 to-red-500/10 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                    <img 
                      src="https://cdn-icons-png.flaticon.com/512/2702/2702605.png" 
                      alt="calendar" 
                      className="w-5 h-5 mr-2 opacity-80 group-hover:opacity-100 transition-opacity"
                    />
                    <span className="relative z-10">Agendar</span>
                  </button>
                </div>

                <button className="group w-full py-3 px-6 bg-opacity-20 bg-[#000000] backdrop-blur-sm border border-white/30 rounded-lg text-lg font-medium hover:bg-opacity-30 transition-all duration-500 flex items-center justify-center overflow-hidden relative">
                  <span className="absolute inset-0 bg-gradient-to-r from-green-300/10 to-green-500/10 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left"></span>
                  <img 
                    src="https://cdn-icons-png.flaticon.com/512/733/733585.png" 
                    alt="whatsapp" 
                    className="w-5 h-5 mr-2 opacity-80 group-hover:opacity-100 transition-opacity"
                  />
                  <span className="relative z-10">Confirmar asistencia</span>
                </button>
              </div>
            </div>

            {/* Spider-Man images instead of Sulley */}
            <div data-aos="fade-right" className="absolute top-20 left-0 w-24 h-24 animate-bounce">
              <img 
                src={datosSeccionPotada?.fotoIzquierda || "https://i.pinimg.com/originals/d3/a4/98/d3a498e87019df9a74cb21902628d630.png"}
                alt="Left decoration" 
                className="w-full h-full object-contain"
              />
            </div>
            <div data-aos="fade-left" className="absolute bottom-32 right-0 w-32 h-32 animate-bounce">
              <img 
                src={datosSeccionPotada?.fotoDerecha || "https://i.pinimg.com/originals/d3/a4/98/d3a498e87019df9a74cb21902628d630.png"}
                alt="Right decoration" 
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @keyframes fallingStar {
          0% {
            transform: translateY(-10px) translateX(10px);
            opacity: 1;
          }
          100% {
            transform: translateY(100vh) translateX(-100px);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  )
}