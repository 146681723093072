import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaFacebookF, FaInstagram, FaFacebookMessenger } from "react-icons/fa";
import { motion } from 'framer-motion';
import { CheckIcon, XIcon, ExternalLinkIcon, SparklesIcon } from 'lucide-react';
import './Home.css';

const plans = [
  {
    name: 'PLATA',
    originalPrice: 50,
    price: 19.95,
    color: 'bg-gradient-to-br from-gray-300 to-gray-400',
    features: [
      'Comparte tu invitación a cualquier celular',
      'Recibe confirmaciones por WhatApp o SMS',
      'Descripción de tu evento',
      'Cuenta regresiva',
      'Colores y temas de fondo',
      'Música de fondo',
      'Agradecimiento a los padres',
      'Dirección de la ceremonia y recepción por Google Maps',
      'Hasta 4 de tus fotos favoritas',      
    ],
    excludedFeatures: [
      'Ceremony and Reception location through Google Maps',      
      'Deticated RSVP platform to manage your guests',
      'Unique invitations for each guest, featuring their name, a special message, and the # of passes assigned to them',
      'SMS confirmation with the wedding couple',
      'Event details; motive, day, date and time',
      'Dress code',
      'Accommodation and suggestions section',
      'Link yout gift registry'
    ],
    examples: [{text:'Silver Sweet 16', url:"/en/event-auto?id=3626"}, {text:'Silver wedding', url:'/en/wedding-auto?id=3323'}]
  },
  {
    name: 'DIAMANTE',
    originalPrice: 130,
    price: 59.95,
    color: 'bg-gradient-to-br from-blue-300 to-blue-400',
    features: [
      'TODO LO DE PLATA +',     
      'Invitaciones únicas para cada invitado, con su nombre, un mensaje especial y el número de pases asignados',
      'Confirmación por SMS con la pareja de novios',
      'Fuente y diseño más personalizados',
      'Programa de actividades',
      'Código de vestimenta personalizado',
      'Sección de alojamiento y sugerencias',
      'Mesa de regalos o lluvia de sobres',
      'Galería completa de hasta 10 de tus fotos'
    ],
    examples: [{text:'Diamond XV Años', url:"/event-auto-diamond?idInvitado=49518"}, {text:'Diamond Wedding', url:'/wedding-auto-diamond?idInvitado=49674'}],
    popular: true
  },
  {
    name: 'ORO',
    originalPrice: 90,
    price: 39.95,
    color: 'bg-gradient-to-br from-yellow-300 to-yellow-400',
    features: [
      'TODO LO DE PLATA +',
      'Programa de actividades',
      'Código de vestimenta personalizado',
      'Sección de alojamiento y sugerencias',
      'Mesa de regalos o lluvia de sobres',
      'Galería extendida de hasta 7 fotos'      
    ],
    excludedFeatures: [
      'Ceremony and Reception location through Google Maps',      
      'Deticated RSVP platform to manage your guests',
      'Unique invitations for each guest, featuring their name, a special message, and the # of passes assigned to them',
      'SMS confirmation with the wedding couple',
      'Custom font and style',
    ],
    examples: [{text:'Gold XV Años', url:"/en/event-auto?id=2223"}, {text:'Gold Wedding', url:'/en/wedding-auto?id=3584'}]
  }
];


export const HomeEs = () => {
  const [timeLeft, setTimeLeft] = useState(24 * 59 * 57);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const handlePlanSelect = (planName) => {
    const whatsappMessage = `Hi, I'm interested in the ${planName} package.`;
    const whatsappUrl = `https://m.me/61566533287428?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="mx-auto overflow-x-hidden bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Header Section */}
      <header className="text-center mx-auto bg-gradient-to-b from-white to-gray-100">
        <div className="max-w-4xl mx-auto">
                  <div className="mt-8 flex justify-center">
            <img src="https://i.postimg.cc/1tRMg6j4/Sin-t-tulo-4000-x-2500-px-3.png" alt="Invitaciones" className="w-72 sm:w-[40vh] rounded-3xl transform hover:scale-105 transition-transform duration-300" />
          </div>
          <h2 className="mt-12 text-[#6d6d6d] text-3xl md:text-5xl font-semibold">INVITACIONES DIGITALES</h2>
        </div>
      </header>

      {/* Ejemplos Section */}
      <section className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <div className="grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {[
             { title: "XV Años & Sweet 16", img: "https://i.postimg.cc/kMQXMSdW/Quince_años_&_Sweet_16_(5).png", url: "/event-auto-diamond?idInvitado=49518" },
             { title: "Bodas", img: "https://i.postimg.cc/brCxSB2B/Quince-a-os-Sweet-16-3.png", url: "/wedding-auto-diamond?idInvitado=49674" },
             { title: "Cumpleaños", img: "https://i.postimg.cc/d3jq5b95/Quince_años_&_Sweet_16_(4).png", url: "/en/event-auto?id=4168" },
              
            ].map((example, idx) => (
              <motion.div
                key={example.title}
                className="flex flex-col items-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: idx * 0.2 }}
              >
                <h3 className="text-2xl font-allura text-[#6d6d6d] mb-4">{example.title}</h3>
                <a href={example.url} className="group">
                  <div className="relative rounded-3xl shadow-lg">
                    <img src={example.img} alt={example.title} className="w-full transition-transform duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <span className="text-white text-lg font-semibold">Ver Más</span>
                    </div>
                  </div>
                </a>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Packages Section */}
      <section className="py-20 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-800">Elige tu plan ideal</h1>
          <div className="text-2xl font-semibold mb-12 text-center flex items-center justify-center">
            <SparklesIcon className="inline-block mr-2 text-yellow-500" />
            <span>Special offer ends in: </span>
            <span className="ml-2 text-red-600">{formatTime(timeLeft)}</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-7xl">
            {plans.map((plan, index) => (
              <motion.div
                key={plan.name}
                className={`bg-white rounded-lg shadow-lg overflow-hidden flex flex-col ${plan.popular ? 'border-4 border-blue-500' : ''}`}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <div className={`${plan.color} p-6 text-center`}>
                  <h2 className="text-3xl font-bold text-white">{plan.name}</h2>
                  {plan.popular && <span className="bg-yellow-400 text-gray-800 text-xs font-semibold px-2 py-1 rounded-full uppercase mt-2 inline-block">Most Popular</span>}
                </div>
                <div className="p-8 flex-grow">
                  <div className="text-center mb-8">
                    <span className="text-gray-400 line-through text-xl">${plan.originalPrice}</span>
                    <p className="text-5xl font-bold text-gray-800 mt-2">${plan.price}</p>
                    <p className="text-sm text-green-600 font-semibold mt-2">Ahorra ${ (plan.originalPrice - plan.price).toFixed(2) }
</p>
                  </div>
                  <div className="text-center mb-6 font-semibold text-gray-700">¡Elige cualquier diseño y lo personalizamos a tu gusto!</div>
                  <div className="mb-6">
                    <p className="font-semibold mb-3 text-gray-700">Ejemplos:</p>
                    <ul className="space-y-2">
                      {plan.examples.map((example, i) => (
                        <motion.li key={i} className="flex items-start ml-4 space-x-1" initial={{ opacity: 0, x: -20 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: i * 0.1 }}>
                          <ExternalLinkIcon className="w-5 h-5 text-blue-500 flex shrink-0 mt-2 ml-3" />
                          <a href={example.url} className="text-blue-500 hover:underline">{example.text}</a>
                        </motion.li>
                      ))}
                    </ul>
                  </div>
                  <p className="font-semibold mb-3 text-gray-700">Incluye:</p>
                  <ul className="space-y-3">
                    {plan.features.map((feature, i) => (
                      <motion.li key={i} className="flex items-start space-x-1" initial={{ opacity: 0, x: -80 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: i * 0.1 }}>
                        <CheckIcon className="w-5 h-5 text-green-500 flex-shrink-0 mt-1" />
                        <span className="text-gray-600">{feature}</span>
                      </motion.li>
                    ))}
                  </ul>
                </div>
                <motion.button
                  className="w-full bg-blue-600 text-white py-4 font-bold text-lg uppercase tracking-wide mt-auto hover:bg-blue-700 transition-colors duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handlePlanSelect(plan.name)}
                >
                  Seleccionar
                </motion.button>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-16">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <p className="text-xl mb-4">¿Qué esperas?</p>
          <h2 className="text-4xl font-bold mb-8">CONTÁCTANOS</h2>
          <div className="flex justify-center space-x-8 mb-12">
            <a href="https://www.facebook.com/profile.php?id=61566533287428&mibextid=LQQJ4d" className="text-white hover:text-blue-400 transition-colors duration-300">
              <FaFacebookF size={32} />
            </a>
            <a href="https://www.instagram.com/yourinvitationvip/" className="text-white hover:text-pink-400 transition-colors duration-300">
              <FaInstagram size={32} />
            </a>
            <a href="https://m.me/61566533287428?text=Hello%2C%20I%27d%20like%20an%20invitation%20please" className="text-white hover:text-green-400 transition-colors duration-300">
              <FaFacebookMessenger size={32} />
            </a>
          </div>
          <p className="text-gray-400">Your Invitation VIP | Copyright © 2024</p>
        </div>
      </footer>
    </div>
  );
};

export default HomeEs;
