import React, { useEffect } from 'react';

const PageRedirectWhatsApp = () => {

    useEffect(() => {
        // Add Google Tag Manager scripts
        const script = document.createElement('script');
        script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NG6FP2Q6');
      `;
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NG6FP2Q6"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
        document.body.insertBefore(noscript, document.body.firstChild);

        // Redirect to WhatsApp
        const whatsappMessage = "Hola, me gustaría obtener más información.";
        const whatsappUrl = `https://wa.me/4491516931?text=${encodeURIComponent(whatsappMessage)}`;
        window.location.href = whatsappUrl;
    }, []);


    return (
        <div>Redireccionando...</div>
    );
};

export default PageRedirectWhatsApp;