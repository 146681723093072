import React, { useState } from "react";
import axios from "axios";

export const AddHistorial = ({evento, nombre}) => {
    const [tipo, setTipo] = useState("");
    const [fecha, setFecha] = useState(() => {
        const today = new Date();
        const cdmxOffset = -5 * 60; // CDMX is UTC-5
        const cdmxTime = new Date(today.getTime() + cdmxOffset * 60000);
        return cdmxTime.toISOString().split('T')[0];
    });

    console.log(nombre, evento, fecha);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post("/api/Historial", { nombre, tipo, fecha, evento });
            alert("Historial agregado exitosamente");
            setTipo("");
            setFecha(() => {
                const today = new Date();
                const cdmxOffset = -5 * 60; // CDMX is UTC-5
                const cdmxTime = new Date(today.getTime() + cdmxOffset * 60000);
                return cdmxTime.toISOString().split('T')[0];
            });
        } catch (error) {
            console.error("Error al agregar historial:", error);
            alert("Error al agregar historial");
        }
    };

    return (
        <div className="bg-white shadow-sm rounded p-2 text-sm">
            <form onSubmit={handleSubmit} className="flex items-center space-x-2">
                <select
                    id="tipo"
                    value={tipo}
                    onChange={(e) => setTipo(e.target.value)}
                    required
                    className="border rounded px-2 py-1 text-xs"
                >
                    <option value="">Tipo</option>
                    <option value="Nueva">Nueva</option>
                    <option value="Correccion">Corrección</option>
                    <option value="Express">Express</option>
                    <option value="Urgente">Urgente</option>
                    <option value="Finalizado">Finalizado</option>
                </select>
                <button 
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded text-xs"
                >
                    Agregar
                </button>
            </form>
        </div>
    );
};