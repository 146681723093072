import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const MenuCafe = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(true); // Estado para el modal de promociones
  const [activeCategory, setActiveCategory] = useState('todos');
  const [searchTerm, setSearchTerm] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });

    // Simula la carga de datos del menú
    setMenuItems([
      { id: 1, name: 'Frappe de Carbon Activado', price: 70, image: 'https://i.postimg.cc/4N1HTxPv/f1.png', details: 'Frappe con carbón activado, leche y un toque de vainilla. Ideal para los amantes de las bebidas innovadoras y saludables.', category: 'frappes' },
      { id: 2, name: 'Frappe de Café', price: 70, image: 'https://i.postimg.cc/8zPJkgX2/f2.png', details: 'Frappe de café con leche y sirope de caramelo. Perfecto para una tarde relajante.', category: 'frappes' },
      { id: 3, name: 'Frappe de Taro', price: 70, image: 'https://i.postimg.cc/jSgWL3GL/f3.png', details: 'Frappe de taro con leche y un toque de vainilla. Una bebida exótica y refrescante.', category: 'frappes' },
      { id: 4, name: 'Frappe de Nutella', price: 80, image: 'https://pbs.twimg.com/media/C8qKgyBWsAA2Pw5.png', details: 'Frappe de Nutella con leche y un toque de vainilla. Perfecto para los amantes del chocolate.', category: 'frappes' },
      { id: 5, name: 'Frappe de Matcha', price: 70, image: 'https://i.postimg.cc/KYR2c3kD/matcha.png', details: 'Frappe de matcha con leche y un toque de vainilla. Ideal para los amantes del té verde.', category: 'frappes' },
      { id: 6, name: 'Café Capuchino', price: 50, image: 'https://static.vecteezy.com/system/resources/thumbnails/029/250/363/small_2x/ai-generated-latte-coffee-icon-oil-paint-style-digital-paint-png.png', details: 'Café con leche espumosa y un toque de cacao. Perfecto para una mañana acogedora.', category: 'cafes' },
      { id: 7, name: 'Café Latte', price: 40, image: 'https://i.pinimg.com/originals/fb/2c/53/fb2c53552965bc81f112092e59eb6f7f.png', details: 'Café con leche suave y cremoso, ideal para cualquier momento del día.', category: 'cafes' },
      { id: 8, name: 'Café Expresso', price: 35, image: 'https://static.vecteezy.com/system/resources/thumbnails/041/281/412/small_2x/coffee-cup-transparent-png.png', details: 'Café concentrado y fuerte, perfecto para un impulso de energía.', category: 'cafes' },
      { id: 9, name: 'Café Americano', price: 50, image: 'https://i.postimg.cc/tTkVjscD/c2.png', details: 'Café negro suave y aromático, ideal para cualquier momento del día.', category: 'cafes' },
      { id: 10, name: 'Café Cortado', price: 35, image: 'https://i.postimg.cc/hhFx0mXs/c1.png', details: 'Café expreso con un toque de leche, perfecto para un impulso de energía con un toque suave.', category: 'cafes' },
      { id: 11, name: 'Muffin Chocolate', price: 25, image: 'https://i.postimg.cc/26WNhpxq/muffin.png', details: 'Delicioso muffin de chocolate, perfecto para acompañar tu café.', category: 'postres' },
      { id: 12, name: 'Galleta con Chispas de Chocolate', price: 12, image: 'https://i.etsystatic.com/16534565/r/il/6a8a5c/3684214326/il_1080xN.3684214326_tein.jpg', details: 'Galleta crujiente con chispas de chocolate, ideal para un antojo dulce.', category: 'postres' },
      { id: 13, name: 'Pastel de Chocolate', price: 40, image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdjCQtOrjv1rGYW8O36MdBT2GJGHGX-p05Bg&s', details: 'Exquisito pastel de chocolate, perfecto para los amantes del chocolate.', category: 'postres' },
      { id: 14, name: 'Roles de Sabores', price: 50, image: 'https://franquiciamaster.net/site/uploads/images_brand_products/imgs_productos_can-01.png', details: 'Deliciosos roles con una variedad de sabores, perfectos para acompañar tu café o té.', category: 'postres' },
      { id: 15, name: 'Malteada de Fresa', price: 75, image: 'https://i.postimg.cc/DzHKSf1k/fresa.png', details: 'Malteada de fresa con leche y un toque de vainilla. Refrescante y deliciosa.', category: 'malteadas' },
      { id: 16, name: 'Malteada de Nutella', price: 75, image: 'https://i.postimg.cc/L8ySM3Xs/nutella.pngg', details: 'Malteada de Nutella con leche y un toque de vainilla. Perfecta para los amantes del chocolate.', category: 'malteadas' },
      { id: 17, name: 'Chocolate Caliente', price: 65, image: 'https://static.vecteezy.com/system/resources/thumbnails/031/112/850/small/ai-generated-hot-chocolate-drink-in-a-mug-free-png.png', details: 'Chocolate caliente con leche y un toque de vainilla. Perfecto para los días fríos.', category: 'cafes' },
      { id: 18, name: 'Mochachino', price: 75, image: 'https://png.pngtree.com/png-vector/20231016/ourmid/pngtree-cafe-mocha-coffee-ingredient-png-image_10186714.png', details: 'Café con leche, chocolate y un toque de vainilla. Ideal para los amantes del café y el chocolate.', category: 'cafes' },
      { id: 19, name: 'Taro Caliente', price: 70, image: 'https://cursosbaristacafe.com.mx/storage/2019/05/TARO-LATTE-1024x1010.png', details: 'Bebida caliente de taro con leche y un toque de vainilla. Exótica y reconfortante.', category: 'cafes' },
      { id: 20, name: 'Matcha Caliente', price: 70, image: 'https://static.vecteezy.com/system/resources/thumbnails/033/157/060/small_2x/hot-matcha-green-tea-latte-art-foam-isolated-on-transparent-background-ai-generated-png.png', details: 'Bebida caliente de matcha con leche y un toque de vainilla. Perfecta para los amantes del té verde.', category: 'cafes' },
    ]);

    // Efecto de estrellas cayendo más brillantes
    const createStar = () => {
      const star = document.createElement('div');
      star.className = 'star';
      star.style.left = `${Math.random() * 100}vw`;
      star.style.animationDuration = `${Math.random() * 3 + 2}s`;
      star.style.width = `${Math.random() * 3 + 1}px`;
      star.style.height = star.style.width;
      star.style.opacity = Math.random() * 0.5 + 0.5;
      document.querySelector('.stars-container').appendChild(star);

      setTimeout(() => {
        star.remove();
      }, 5000);
    };

    const starInterval = setInterval(createStar, 200);

    return () => clearInterval(starInterval);
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleClosePromoModal = () => {
    setShowPromoModal(false);
  };

  const filteredItems = menuItems.filter(item => {
    const matchesCategory = activeCategory === 'todos' || item.category === activeCategory;
    const matchesSearch = item.name.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-900 via-purple-900 to-pink-800 text-white relative overflow-hidden">
      <div className="stars-container"></div>
      <style jsx>{`
        @keyframes fall {
          0% { transform: translateY(-150vh) rotate(0deg); opacity: 1; }
          100% { transform: translateY(100vh) rotate(360deg); opacity: 0; }
        }
        .star {
          position: absolute;
          background: white;
          border-radius: 50%;
          animation: fall linear infinite;
          box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.8);
        }
        .stars-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
        }
        @keyframes pulse {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.05); }
        }
        .pulse {
          animation: pulse 2s infinite;
        }
        @keyframes handWave {
          0% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
          100% { transform: translateY(0); }
        }
        .hand {
          position: absolute;
          top: 10px;
          left: 10px;
          font-size: 1.5rem;
          animation: handWave 1s infinite;
        }
      `}</style>
      
      {/* Navbar */}
      <nav className="bg-indigo-900 bg-opacity-90 fixed top-0 left-0 right-0 z-50 shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <button onClick={toggleSidebar} className="text-white p-2 rounded-md hover:bg-indigo-800 transition-colors duration-300">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
              {!isSearchFocused && (
                <h1 className="ml-4 text-xl font-bold font-serif tracking-wide pulse">Menú Moon Café</h1>
              )}
            </div>
            <div className={`flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end transition-all duration-300 ${isSearchFocused ? 'w-full' : ''}`}>
              <div className={`max-w-lg w-full lg:max-w-xs ${isSearchFocused ? 'w-full' : ''}`}>
                <label htmlFor="search" className="sr-only">Buscar</label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <input
                    id="search"
                    className={`block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-800 text-white placeholder-gray-400 focus:outline-none focus:bg-indigo-700 focus:border-white focus:ring-white focus:text-white sm:text-sm transition-all duration-300 ${isSearchFocused ? 'w-full' : ''}`}
                    placeholder="Buscar productos"
                    type="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-indigo-900 bg-opacity-90 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out pt-16`}>
        <div className="p-5">
          <button onClick={toggleSidebar} className="absolute top-4 right-4 text-white hover:text-pink-300 transition-colors duration-300">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="text-2xl font-bold mb-5 text-pink-300">Categorías</h2>
          {['todos', 'cafes', 'frappes', 'malteadas', 'postres'].map((category) => (
            <button
              key={category}
              onClick={() => {
                setActiveCategory(category);
                setIsSidebarOpen(false);
              }}
              className={`block w-full text-left px-3 py-2 rounded-md text-sm font-medium mb-2 transition-colors duration-300 ${
                activeCategory === category
                  ? 'bg-indigo-700 text-white'
                  : 'text-gray-300 hover:bg-indigo-800 hover:text-white'
              }`}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
          <button
            onClick={() => window.open('https://g.co/kgs/zE2Tu8S', '_blank')}
            className="block w-full text-left px-4 py-3 rounded-lg text-sm font-medium mb-3 transition-transform duration-300 text-gray-300 bg-indigo-700 hover:bg-indigo-800 hover:text-white transform hover:scale-105"
          >
            Ver Ubicación
          </button>
          <button
            onClick={() => window.open('https://www.instagram.com/moonsscoffee?igsh=NnN3ZDgyM3B5dmtv', '_blank')}
            className="block w-full text-left px-4 py-3 rounded-lg text-sm font-medium mb-3 transition-transform duration-300 text-gray-300 bg-indigo-700 hover:bg-indigo-800 hover:text-white transform hover:scale-105"
          >
            Ver Instagram
          </button>
        </div>
      </div>

      {/* Main content */}
      <div className="relative z-10 pt-24 pb-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 sm:gap-6">
            {filteredItems.map((item) => (
              <div 
                key={item.id} 
                className="bg-white bg-opacity-10 rounded-xl overflow-hidden shadow-lg cursor-pointer transition-all duration-300 hover:bg-opacity-20 hover:shadow-2xl transform hover:-translate-y-1" 
                data-aos="fade-up"
                onClick={() => handleItemClick(item)}
              >
                <div className="relative">
                  <img src={item.image} alt={item.name} className="w-full h-32 mt-4 sm:h-40 object-contain" />
                  <div className="hand">👇</div>
                </div>
                <div className="p-3 sm:p-4">
                  <h3 className="text-lg sm:text-xl font-semibold mb-1">{item.name}</h3>
                  <p className="text-base sm:text-lg text-pink-300">${item.price.toFixed(2)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50 overflow-y-auto">
          <div className="bg-gradient-to-br from-indigo-800 to-purple-700 rounded-2xl w-full max-w-xs sm:max-w-sm md:max-w-md shadow-2xl transform transition-all duration-300 scale-95 hover:scale-100">
            <div className="p-4 sm:p-6">
              <h2 className="text-xl sm:text-2xl font-bold mb-3 text-pink-300">{selectedItem.name}</h2>
              <img src={selectedItem.image} alt={selectedItem.name} className="w-full h-40 sm:h-48 object-contain rounded-xl mb-4 shadow-lg" />
              <h4 className="font-semibold text-base mb-1 text-indigo-200">Detalles</h4>
              <p className="text-gray-200 text-sm mb-3">{selectedItem.details}</p>
              <div className="flex justify-between items-center mb-3">
                <div>
                  <h4 className="font-semibold text-base text-indigo-200">Precio</h4>
                  <p className="text-xl text-pink-300">${selectedItem.price.toFixed(2)}</p>
                </div>
               
              </div>
              <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3">
                <button 
                  onClick={handleCloseModal} 
                  className="w-full sm:w-auto bg-white font-semibold text-purple-700 px-4 py-2 rounded-full hover:bg-pink-100 transition-colors duration-300 text-sm"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPromoModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50 overflow-y-auto">
          <div className="bg-gradient-to-br from-indigo-800 to-purple-700 rounded-2xl w-full max-w-xs sm:max-w-sm md:max-w-md shadow-2xl transform transition-all duration-300 scale-95 hover:scale-100">
            <div className="p-4 sm:p-6">
              <h2 className="text-xl sm:text-2xl font-bold mb-3 text-pink-300">Recomendación del Día</h2>
              <img src="https://i.postimg.cc/htRsPkXy/recomendacion-Taro.png" alt="Promociones del Día" className="w-full h-auto sm:h-48 object-contain rounded-xl mb-4 shadow-lg" />
              <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3">
                <button 
                  onClick={handleClosePromoModal} 
                  className="w-full sm:w-auto bg-white font-semibold text-purple-700 px-4 py-2 rounded-full hover:bg-pink-100 transition-colors duration-300 text-sm"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
