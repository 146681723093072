import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowDown } from "react-icons/fa6";
import { FaGem, FaStar, FaMedal } from "react-icons/fa";
import { motion } from "framer-motion";

// Example images - replace with actual paths
const headerImages = [
  "https://i.postimg.cc/vm64RVvN/processed-F06-DD24-A-19-AE-4955-9-A95-87-E55-A61-B7-AA.jpg",
  "https://digitalrsvp.mx/api/Images/11deb978-d2b8-4841-9549-d6632e58951c",
  "https://i.postimg.cc/6qz3VL9y/processed-D9-CCB483-842-C-4-C9-C-962-D-49-C384-AE84-FF.jpg",
  "https://i.postimg.cc/QNqHrsph/processed-91-FF5269-18-A0-406-E-8-C70-D255-E1-CFA6-EC.jpg",
];
const plataImage =
  "https://i.postimg.cc/vm64RVvN/processed-F06-DD24-A-19-AE-4955-9-A95-87-E55-A61-B7-AA.jpg";
const oroImage =
  "https://i.postimg.cc/xC3TYyNt/processed-8-B45-EF6-D-415-F-4-A5-E-A01-D-AD55-A76-F9-B36.jpg";
const diamanteImage =
  "https://i.postimg.cc/QNqHrsph/processed-91-FF5269-18-A0-406-E-8-C70-D255-E1-CFA6-EC.jpg";

const packages = [
  {
    name: "DIAMANTE",
    tagline: "La Experiencia Definitiva",
    icon: <FaGem className="text-4xl text-[#7FE6FF] drop-shadow-[0_0_8px_rgba(127,230,255,0.6)]" />,
    imageUrl: diamanteImage,
    designs: [
      {
        preciceDec: "450.000",
        precice: "219.900",
        name: "Ejemplo con Fotos",
        link: "/boda-diamante-ejemplo?name=Angel%20Jaimes",
        name2: "Ejemplo sin Fotos",
        link2: "/boda-diamante-ejemplo-fotos?name=Angel%20Jaimes",
      },
    ],
  },
  {
    name: "ORO",
    icon: <FaStar className="text-3xl text-[#FFD700]" />,
    imageUrl: oroImage,
    designs: [
      {
        preciceDec: "300.000",
        precice: "134.900",
        name: "Ejemplo con Fotos",
        link: "/boda-oro-ejemplo",
        name2: "Ejemplo sin Fotos",
        link2: "/boda-oro-ejemplo-fotos",
      },
    ],
  },
  {
    name: "PLATA",
    icon: <FaMedal className="text-3xl text-[#B0B0B0]" />,
    imageUrl: plataImage,
    designs: [
      {
        preciceDec: "150.000",
        precice: "64.900",
        name: "Ejemplo con Fotos",
        link: "/boda-plata-ejemplo",
        name2: "Ejemplo sin Fotos",
        link2: "/boda-plata-ejemplo-fotos",
      },
    ],
  },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

export const EjemplosBodaCo = () => {
  
useEffect(() => {
  const script = document.createElement('script');
  script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16738412224";
  script.async = true;
  document.head.appendChild(script);

  const scriptText = document.createElement('script');
  scriptText.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-16738412224');
  `;
  document.head.appendChild(scriptText);

  return () => {
    document.head.removeChild(script);
    document.head.removeChild(scriptText);
  };
}, []);
  return (
    <div className="relative w-full min-h-screen bg-gradient-to-b from-gray-100 to-gray-200"
    style={{
      backgroundImage: `url('https://digitalrsvp.mx/api/Images/ac65eeb8-5eb3-4223-9ddf-0e1292e1a9c6')`,
    }}>
      <div className="relative mx-auto overflow-x-hidden max-w-7xl">
        {/* Header Section */}
        <div className="w-full shadow-lg">
          <Slider {...sliderSettings}>
            {headerImages.map((image, index) => (
              <div key={index} className="relative h-[60vh] md:h-[70vh]">
                <img
                  src={image}
                  className="h-full w-full object-cover"
                  alt=""
                />
                <div className="absolute inset-0 bg-opacity-40 flex items-center justify-center">
                  <p className="text-2xl md:text-4xl font-serif text-white text-center px-4 py-2 bg-black bg-opacity-50 rounded-lg">
                    Invitaciones para Bodas
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* Packages Section */}
        <motion.div 
          className="flex flex-col md:flex-row justify-around gap-8 p-8 mt-16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {packages.map((pkg, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: "easeOut"
              }}
              whileHover={{ scale: 1.03, translateY: -5 }}
              className={`flex-1 bg-gradient-to-b from-black to-[#111] p-6 rounded-2xl transform relative shadow-lg
                ${pkg.name === "DIAMANTE" ? 
                  "border-[3px] border-[#7FE6FF] scale-105 shadow-[0_0_40px_rgba(127,230,255,0.2)] z-10" :
                pkg.name === "ORO" ? 
                  "border-[3px] border-[#FFD700]" :
                  "border-[3px] border-[#B0B0B0]"
                }`}
            >
              {pkg.name === "DIAMANTE" && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#7FE6FF] text-black text-center text-[10px] font-bold px-4 py-1 rounded-full shadow-md">
                  MÁS POPULAR
                </div>
              )}

              <div className="relative pb-2 mb-3">
                <div className="flex items-center justify-center mb-2">
                  {pkg.icon}
                </div>
                <h2 className="text-lg font-['Playfair Display'] font-bold text-white text-center tracking-wider">
                  {pkg.name}
                </h2>
                {pkg.tagline && (
                  <p className="text-[#7FE6FF] text-[10px] text-center mt-1">
                    {pkg.tagline}
                  </p>
                )}
                <div className={`absolute bottom-0 left-0 right-0 h-[1px] ${
                  pkg.name === "DIAMANTE" ? "bg-[#7FE6FF]" :
                  pkg.name === "ORO" ? "bg-[#FFD700]" :
                  "bg-[#B0B0B0]"
                }`}></div>
              </div>

              <img
                src={pkg.imageUrl}
                alt={`${pkg.name} package`}
                className="w-full h-64 object-cover rounded-lg mb-4"
              />

              {pkg.designs.map((design, idx) => (
                <div key={idx} className="text-center">
                  <div className="mb-4 text-center flex flex-col items-center">
                    <span className="text-[#FFD700] font-bold block mb-2 text-sm">
                      ¡Oferta!
                    </span>
                    <div className="flex items-center gap-2 mb-1">
                      <span className="text-[#FFD700] text-sm">
                        -{Math.round((1 - parseInt(design.precice.replace(/\./g, '')) / parseInt(design.preciceDec.replace(/\./g, ''))) * 100)}%
                      </span>
                      <span className="text-white text-lg font-bold">
                        ${design.precice}
                      </span>
                    </div>
                    <span className="text-[#B0B0B0] text-[11px]">
                      Antes <span className="line-through">${design.preciceDec}</span>
                    </span>
                  </div>
                  <div className="space-y-3">
                    <a
                      href={design.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-4 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 text-white rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                    >
                      <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                      </svg>
                      <span>{design.name}</span>
                    </a>
                    
                    <a
                      href={design.link2}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-4 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 text-white rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                    >
                      <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                      </svg>
                      <span>{design.name2}</span>
                    </a>
                  </div>
                </div>
              ))}
            </motion.div>
          ))}
        </motion.div>

        {/* Comentada la sección de "Cómo adquirir tu invitación" */}
        {/*
        <div className="p-8 w-full flex flex-col items-center bg-white bg-opacity-90 rounded-lg shadow-xl mt-16">
          <h1 className="text-3xl text-center font-serif mb-6 text-gray-800">
            Cómo adquirir tu invitación
          </h1>
          <FaArrowDown className="text-5xl text-blue-600 animate-bounce mb-8" />
        </div>
        */}
      </div>
    </div>
  );
};
