import { BodaDetalles } from "../screens/Admin/BodaDetalles"
import { Eventos } from "../screens/Admin/Eventos/Eventos"
import { XvDetalles } from "../screens/Admin/XvDetalles"
import {FormularioXvAuto} from '../screens/Admin/FormsComponent/FormularioXvAuto'
import {FormularioXvAuto as  FormularioXvAutoEn} from '../screens/Admin/FormsComponent/ingles/FormularioXvAuto'
import {FormularioBodaAuto as  FormularioBodaAutoEn} from '../screens/Admin/FormsComponent/ingles/FormularioBodaAuto'
import { FormularioBodaAuto } from "../screens/Admin/FormsComponent/FormularioBodaAuto"
import InvitadosTabla from "../components/ComponentsAuto/InvitadosTabla"
import { InvitacionesXv } from "../screens/Home/EjemplosInvitacionesXv/InvitacionesXv"
import { LoginEventos } from "../screens/Admin/Eventos/LoginEventos"
import { EjemplosXv } from "../screens/Admin/EjemplosPage/EjemplosXv"
import { EjemplosBoda } from "../screens/Admin/EjemplosPage/EjemplosBoda"
import { EventosSocios } from "../screens/Admin/Eventos/EventosSocios"
import Dashboard from "../screens/Admin/dashboard/Dashboard"
import InvitadosTablaEn from "../components/ComponentsAuto/InvitadosTablaEn"
import DashboardSocio from "../screens/Admin/dashboard/DashboardSocio"
import { EjemplosBodaEn } from "../screens/Admin/EjemplosPage/Ingles/EjemplosBodaEn"
import { EjemplosXvEn } from "../screens/Admin/EjemplosPage/Ingles/EjemplosXvEn"
import { Iconos } from "../screens/Iconos/Iconos"
import { HomeEn } from "../components/HomeEn"
import LinkClient from "../components/ComponentsAuto/LinkClient"
import { MenuCafe } from "../screens/Cafeteria/MenuCafe"
import PageRedirectWhatsApp from "../screens/Admin/Eventos/PageRedirectWhatsApp"
import { InfantilAutomaticoPlata } from "../screens/CumpleInfantil/Automatico/InfantilAutomaticoPlata"

const RoutesAdmin = [
    {
        path: '/admin-login',
        element: <LoginEventos />
    },
    {
        path: '/en',
        element: <HomeEn />
    },
    {
        path: '/admin-dashboard',
        element: <Dashboard />
    },
    {
        path: '/admin-dashboard-socio',
        element: <DashboardSocio />
    },
    {
        path: '/ejemplos-xv',
        element: <EjemplosXv />,
        meta: {
            ogImage: 'https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg'
        }
    },
    {
        path: '/ejemplos-boda',
        element: <EjemplosBoda/>,
        meta: {
            ogImage: 'https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg'
        }
    },
    {
        path: '/example-wedding',
        element: <EjemplosBodaEn/>,
        meta: {
            ogImage: 'https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg'
        }
    },
    {
        path: '/example-xv',
        element: <EjemplosXvEn />,
        meta: {
            ogImage: 'https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg'
        }
    },
    {
        path: '/admin-eventos',
        element: <Eventos />
    },
    {
        path: '/admin-eventos-socios',
        element: <EventosSocios />
    },
    {
        path: '/admin-xv-detalles',
        element: <XvDetalles />
    },
    {
        path: '/admin-boda-detalles',
        element: <BodaDetalles />
    },
    {
        path: '/formulario-xv',
        element: <FormularioXvAuto />
    },
    {
        path: '/form-event',
        element: <FormularioXvAutoEn />
    },
    {
        path: '/form-wedding',
        element: <FormularioBodaAutoEn/>
    },
    {
        path: '/formulario-boda',
        element: <FormularioBodaAuto />
    },
    {
        path: '/invitados-generate',
        element: <InvitadosTabla />
    },
    {
        path: '/guests-generate',
        element: <InvitadosTablaEn />
    },
    {
        path: '/invitaciones-xv',
        element: <InvitacionesXv />
    },
    {
        path: '/compartir-invitacion',
        element: <LinkClient />
    },
    {
        path: '/iconos',
        element: <Iconos />
    },
    {
        path: '/cafe',
        element: <MenuCafe />
    },
    {
        path: '/whats',
        element: <PageRedirectWhatsApp />
    },
    {
        path: '/infantil-automatico',
        element: <InfantilAutomaticoPlata />
    },
]

export default RoutesAdmin