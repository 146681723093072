import React from 'react'
import foto from '../assets/invitationLogo.png'

export const LoadingComponentEn = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="relative">
        {/* Imagen del Sobre */}
        <div className="animate-bounce">
          <img
            src={foto}
            alt="Envelope"
            className="w-28 h-28"
          />
        </div>

        {/* Spinner */}
        <div className="absolute inset-0 mt-[10vh] flex items-center justify-center">
          <div className="w-12 h-12 border-4 border-dashed rounded-full animate-spin border-blue-500"></div>
        </div>

        {/* Texto de Carga */}
        <div className="mt-[10vh] text-center text-gray-700">
          <p className="text-sm">Loading...</p>
        </div>
      </div>
    </div>
  )
}
