import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaArrowDown, FaStar, FaGem, FaMedal } from "react-icons/fa";
import videoProceso from "../../../assets/proceso-compra.mp4";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const packages = [
  {
    name: "PAQUETE DIAMANTE",
    icon: <FaGem className="text-4xl text-blue-500" />,
    imageUrl: "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
    designs: [
      {
        preciceDec: "2500",
        precice: 895,
        discount: "64% OFF",
        name: "Ejemplo con Fotos",
        link: "/evento-diamante-xv?name=Angel%20Jaimes",
        name2: "Ejemplos sin Fotos",
        link2: "/evento-diamante-xv-foto?name=Angel%20Jaimes",
      },
    ],
  },
  {
    name: "PAQUETE ORO",
    icon: <FaStar className="text-4xl text-yellow-500" />,
    imageUrl: "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
    designs: [
      {
        preciceDec: "1200",
        precice: 595,
        discount: "50% OFF",
        name: "Ejemplo con Fotos",
        link: "/evento-oro-xv",
        name2: "Ejemplos sin Fotos",
        link2: "/evento-oro-xv-fotos",
      },
    ],
  },
  {
    name: "PAQUETE PLATA",
    icon: <FaMedal className="text-4xl text-gray-400" />,
    imageUrl: "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
    designs: [
      {
        preciceDec: "600",
        precice: 295,
        discount: "51% OFF",
        name: "Ejemplo con Fotos",
        link: "/evento-plata-xv",
        name2: "Ejemplo sin Fotos",
        link2: "/evento-plata-xv-fotos",
      },
    ],
  },
];

const sliderImages = [
  "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
  "https://i.postimg.cc/BbFjyFfF/Beauty-Flowers-Watercolor-Background-Instagram-Story.png",
  "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
  "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
];

export const EjemplosXv = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [typedText, setTypedText] = useState("");
  const fullText = "Invitaciones de Quinceañera";
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16738412224";
    script.async = true;
    document.head.appendChild(script);

    const scriptText = document.createElement('script');
    scriptText.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16738412224');
    `;
    document.head.appendChild(scriptText);

    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < fullText.length) {
        setTypedText(fullText.slice(0, i + 1));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptText);
      clearInterval(typingInterval);
    };
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="relative w-full min-h-screen bg-gradient-to-b from-purple-100 to-pink-200">
      <div className="relative mx-auto overflow-x-hidden">
        {/* Header Section with Slider */}
        <div className="w-full h-[80vh] relative overflow-hidden">
          <Slider {...sliderSettings}>
            {sliderImages.map((image, index) => (
              <div key={index} className="w-full h-[80vh]">
                <img 
                  src={image}
                  alt={`Quinceañera Celebration ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </Slider>
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
            <motion.h1 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="text-4xl md:text-6xl font-serif text-white text-center mb-4"
            >
              {typedText}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="text-xl md:text-2xl text-white text-center"
            >
              Descubre nuestros paquetes exclusivos
            </motion.p>
          </div>
        </div>

        {/* Packages Section */}
        <motion.div 
         className="flex flex-col md:flex-row justify-around gap-8 p-8 mt-16"
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 0.5 }}
       >
         {packages.map((pkg, index) => (
           <motion.div
             key={index}
             initial={{ opacity: 0, y: 50 }}
             whileInView={{ opacity: 1, y: 0 }}
             viewport={{ once: true }}
             transition={{ 
               duration: 0.7,
               delay: index * 0.2,
               ease: "easeOut"
             }}
             whileHover={{ scale: 1.03, translateY: -5 }}
             className={`flex-1 bg-gradient-to-b from-black to-[#111] p-6 rounded-2xl transform relative shadow-lg
               ${pkg.name === "PAQUETE DIAMANTE" ? 
                 "border-[3px] border-[#7FE6FF] scale-105 shadow-[0_0_40px_rgba(127,230,255,0.2)] z-10" :
               pkg.name === "PAQUETE ORO" ? 
                 "border-[2px] border-[#FFD700]" :
                 "border-[2px] border-[#B0B0B0]"
               }`}
           >
             {pkg.name === "PAQUETE DIAMANTE" && (
               <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#7FE6FF] text-black text-xs font-bold px-4 py-1 rounded-full shadow-lg text-center">
                 MÁS POPULAR
               </div>
             )}
              
             <div className="relative pb-2 mb-3">
               <div className="flex items-center justify-center mb-2">
                 {pkg.icon}
               </div>
               <h2 className="text-lg font-['Playfair Display'] font-bold text-white text-center tracking-wider">
                 {pkg.name}
               </h2>
               {pkg.tagline && (
                 <p className="text-[#7FE6FF] text-xs text-center mt-1">
                   {pkg.tagline}
                 </p>
               )}
               <div className={`absolute bottom-0 left-0 right-0 h-[1px] ${
                 pkg.name === "PAQUETE DIAMANTE" ? "bg-[#7FE6FF]" :
                 pkg.name === "PAQUETE ORO" ? "bg-[#FFD700]" :
                 "bg-[#B0B0B0]"
               }`}></div>
             </div>

             <img
               src={pkg.imageUrl}
               alt={`${pkg.name} package`}
               className="w-full h-64 object-cover rounded-lg mb-4"
             />

             {pkg.designs.map((design, idx) => (
               <div key={idx} className="text-center">
                 <div className="mb-4 text-center">
                   <span className="text-[#FFD700] font-bold block mb-2 text-sm">
                     ¡Oferta!
                   </span>
                   <div className="flex items-center justify-center gap-2 mb-1">
                     <span className="text-[#FFD700] text-sm">
                       -{Math.round((1 - parseInt(design.precice) / parseInt(design.preciceDec.replace(/\./g, ''))) * 100)}%
                     </span>
                     <span className="text-white text-lg font-bold">
                       ${design.precice}
                     </span>
                   </div>
                   <span className="text-[#B0B0B0] text-[11px]">
                     Antes <span className="line-through">${design.preciceDec}</span>
                   </span>
                 </div>

                 <div className="space-y-3">
                   <a
                     href={design.link}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="flex items-center gap-4 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 text-white rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                   >
                     <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                       <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                     </svg>
                     <span>{design.name}</span>
                   </a>
                    
                   <a
                     href={design.link2}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="flex items-center gap-4 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 text-white rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                   >
                     <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                       <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                     </svg>
                     <span>{design.name2}</span>
                   </a>
                 </div>
               </div>
             ))}
           </motion.div>
         ))}
       </motion.div>

        {/* Selected Package Details */}
        <AnimatePresence>
          {selectedPackage && (
            <motion.div 
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              className="mt-16 p-8 bg-white bg-opacity-90 rounded-lg shadow-2xl"
            >
              <h2 className="text-3xl font-serif font-bold text-center mb-8">{selectedPackage.name}</h2>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <img
                  src={selectedPackage.imageUrl}
                  alt={`${selectedPackage.name} package`}
                  className="w-full md:w-1/2 h-[50vh] rounded-lg shadow-lg object-cover mb-8 md:mb-0"
                />
                <div className="md:w-1/2 md:ml-8">
                  {selectedPackage.designs.map((design, idx) => (
                    <div
                      key={idx}
                      className="p-6 text-center bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg shadow-lg"
                    >
                      <h3 className="text-2xl font-semibold mb-4 text-gray-800">Opciones de Diseño</h3>
                      <div className="space-y-4">
                        <a
                          href={design.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block py-3 px-6 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-full hover:from-blue-600 hover:to-blue-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                        >
                          {design.name}
                        </a>
                        <a
                          href={design.link2}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block py-3 px-6 bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-full hover:from-purple-600 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                        >
                          {design.name2}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* How to Purchase Section */}
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="p-8 w-full flex flex-col items-center bg-white bg-opacity-90 rounded-lg shadow-xl mt-16"
        >
          <h1 className="text-4xl text-center font-serif mb-6 text-gray-800">Cómo adquirir tu invitación</h1>
          <FaArrowDown className="text-5xl text-blue-600 animate-bounce mb-8" />
          <div className="relative w-full md:w-3/4 lg:w-2/3 xl:w-1/2" style={{ paddingBottom: "56.25%" }}>
            <video
              className="absolute inset-0 w-full h-full object-contain rounded-lg shadow-lg"
              src={videoProceso}
              controls
              autoPlay
              loop
              muted
            />
          </div>
        </motion.div>
        
        <div className="h-16"></div> {/* Spacer */}
      </div>
    </div>
  );
};
