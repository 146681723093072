import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import axios from "axios";
import { Chart, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { validateToken } from "../../../components/validateToken";

Chart.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [dataDiaria, setDataDiaria] = useState(null);
  const [dataMensual, setDataMensual] = useState(null);
  const [dataQuincenal, setDataQuincenal] = useState(null);
  const [dataVendedoras, setDataVendedoras] = useState(null);
  const [historialSemanal, setHistorialSemanal] = useState(null);
  const [ultimoDatoDiaria, setUltimoDatoDiaria] = useState(null);
  const [userName, setUserName] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedWeek, setSelectedWeek] = useState(getWeekNumber(new Date()));
  const [totalVentas, setTotalVentas] = useState(0);
  const [promedioVentasDiarias, setPromedioVentasDiarias] = useState(0);
  const [tendenciaVentas, setTendenciaVentas] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [weekDates, setWeekDates] = useState({ start: null, end: null });
  const [selectedDate, setSelectedDate] = useState('');
  const [eventosMontoMenorQueTotal, setEventosMontoMenorQueTotal] = useState([]);
  const [filterFechaFin, setFilterFechaFin] = useState('');

  useEffect(() => {
    const validateAndFetchData = async () => {
      const admin = await validateToken();
      if (admin) setUserName(admin);

      const fetchVentasDiarias = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-diarias", {
            params: { year: 2024, month: selectedMonth },
          });
          const data = response.data;
          setDataDiaria(data);
          setUltimoDatoDiaria(data[data.length - 1]); 
          
          const totalVentas = data.reduce((sum, day) => sum + day.totalTotales, 0);
          setTotalVentas(totalVentas);
          setPromedioVentasDiarias(totalVentas / data.length);

          setTendenciaVentas(data.map(day => day.totalTotales));
        } catch (error) {
          console.error("Error fetching daily sales data", error);
        }
      };

      const fetchVentasMensuales = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-mensuales", {
            params: { year: 2024 },
          });
          setDataMensual(response.data);
        } catch (error) {
          console.error("Error fetching monthly sales data", error);
        }
      };

      const fetchVentasQuincenales = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-quincenales", {
            params: { year: 2024 },
          });
          setDataQuincenal(response.data);
        } catch (error) {
          console.error("Error fetching biweekly sales data", error);
        }
      };

      const fetchVentasVendedoras = async () => {
        try {
          const response = await axios.get("/api/Eventos/ventas-diarias-vendedoras", {
            params: { year: 2024, weekNumber: selectedWeek },
          });
          setDataVendedoras(response.data);
          console.error("seller", response.data);
        } catch (error) {
          console.error("Error fetching sales data by seller", error);
        }
      };

      const fetchHistorial = async () => {
        try {
          const response = await axios.get("/api/Eventos/historial-semanal", {
            params: { year: 2024, weekNumber: selectedWeek },
          });
          setHistorialSemanal(response.data);
          console.log("historial", response.data);
        } catch (error) {
          console.error("Error fetching weekly history data", error);
        }
      };

      const fetchEventosMontoMenorQueTotal = async () => {
        try {
          const response = await axios.get("/api/Eventos/monto-menor-que-total");
          setEventosMontoMenorQueTotal(response.data);
          console.log(response.data)
        } catch (error) {
          console.error("Error fetching events with monto less than total", error);
        }
      };

      fetchHistorial();
      fetchVentasDiarias();
      fetchVentasMensuales();
      fetchVentasQuincenales();
      fetchVentasVendedoras();
      fetchEventosMontoMenorQueTotal();
    };

    validateAndFetchData();
    setWeekDates(getWeekDates(2024, selectedWeek));
  }, [selectedMonth, selectedWeek]);

  if (!dataDiaria || !dataMensual || !dataQuincenal || !dataVendedoras || !historialSemanal) return <LoadingComponent />;

  const handleMonthChange = (event) => {
    setSelectedMonth(Number(event.target.value));
  };

  const handleWeekChange = (event) => {
    setSelectedWeek(Number(event.target.value));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const chartDataDiaria = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Ventas Diarias",
        data: dataDiaria.map(d => ({
          x: `Día ${d.dia}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
          cantidadEventos: d?.cantidadEventos,
        })),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const chartDataMensual = {
    labels: dataMensual.map(d => `Mes ${d.mes}`),
    datasets: [
      {
        label: "Ventas Mensuales",
        data: dataMensual.map(d => ({
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
        })),
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  const chartDataQuincenal = {
    labels: [...new Set(dataQuincenal.map(d => `Mes ${d.mes}`))],
    datasets: dataQuincenal.map((d, index) => ({
      label: `Quincena ${d.quincena}`,
      data: [
        {
          x: `Mes ${d.mes}`,
          y: d.totalTotales,
          totalMontos: d.totalMontos,
          fechaInicio: d.fechaInicio,
          fechaFin: d.fechaFin,
        },
      ],
      backgroundColor: `rgba(${255 - index * 40}, ${159 - index * 20}, 64, 0.6)`,
    })),
  };

  const tendenciaVentasData = {
    labels: dataDiaria.map(d => `Día ${d.dia}`),
    datasets: [
      {
        label: "Tendencia de Ventas",
        data: tendenciaVentas,
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataPoint = context.raw;
            return [
              `Eventos: ${dataPoint?.cantidadEventos}`,
              `Montos: ${dataPoint.totalMontos}`,
              `Totales: ${dataPoint.y}`,
              `Rango: ${new Date(dataPoint.fechaInicio).toLocaleDateString()} - ${new Date(dataPoint.fechaFin).toLocaleDateString()}`
            ];
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <button 
        className="md:hidden fixed top-4 left-4 z-50 bg-blue-600 text-white p-2 rounded-full shadow-lg"
        onClick={toggleSidebar}
      >
        ☰
      </button>
      <aside className={`w-full md:w-80 bg-gradient-to-b from-blue-800 to-blue-600 text-white ${isSidebarOpen ? 'block' : 'hidden'} md:block shadow-lg fixed md:relative z-40 h-full overflow-y-auto transition-all duration-300 ease-in-out`}>
        <div className="p-6 text-2xl font-bold border-b border-blue-500">DIGITAL RSVP</div>
        {userName && <h2 className="p-4 text-center font-medium bg-blue-700">EXCELENTE DÍA 🤑 <br />{userName}</h2>}
        <nav className="mt-6">
          <ul className="space-y-2">
            <li className="p-4 hover:bg-blue-700 cursor-pointer transition duration-300 ease-in-out">Ventas Diarias</li>
            <li className="p-4 hover:bg-blue-700 cursor-pointer transition duration-300 ease-in-out">Ventas Mensuales</li>
            <li className="p-4 hover:bg-blue-700 cursor-pointer transition duration-300 ease-in-out">Ventas Quincenales</li>
          </ul>
        </nav>
        {ultimoDatoDiaria && (
          <div className="p-4 bg-blue-700 mt-8 rounded-lg mx-2 shadow-md">
            <h3 className="text-lg font-semibold mb-2 border-b pb-2">Última Venta</h3>
            <p className="text-sm mb-1">Fecha: <span className="font-medium">{`Día ${ultimoDatoDiaria.dia}`}</span></p>
            <p className="text-sm mb-1">Total de Ventas: <span className="font-medium">{ultimoDatoDiaria.cantidadEventos}</span></p>
            <p className="text-sm mb-1">Total Monto: <span className="font-medium">${ultimoDatoDiaria.totalMontos}</span></p>
            <p className="text-sm">Total Total: <span className="font-medium">${ultimoDatoDiaria.totalTotales}</span></p>
          </div>
        )}
      </aside>

      <main className="flex-grow p-4 md:p-8 mt-16 md:mt-0">
        <h1 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-gray-800">Dashboard de Ventas</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div className="bg-white p-4 rounded-lg shadow">
            <label htmlFor="month" className="block text-lg font-semibold mb-2 text-gray-700">Selecciona el Mes:</label>
            <select id="month" value={selectedMonth} onChange={handleMonthChange} className="p-2 border rounded w-full">
              {[...Array(12).keys()].map(i => (
                <option key={i} value={i + 1}>{new Date(2024, i).toLocaleString('default', { month: 'long' })}</option>
              ))}
            </select>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <label htmlFor="week" className="block text-lg font-semibold mb-2 text-gray-700">Selecciona la Semana:</label>
            <select id="week" value={selectedWeek} onChange={handleWeekChange} className="p-2 border rounded w-full">
              {[...Array(52).keys()].map(i => (
                <option key={i} value={i + 1}>Semana {i + 1}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Total Ventas</h3>
            <p className="text-3xl font-bold text-blue-600">${totalVentas.toFixed(2)}</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Promedio Diario</h3>
            <p className="text-3xl font-bold text-green-600">${promedioVentasDiarias.toFixed(2)}</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-semibold mb-2">Eventos Totales</h3>
            <p className="text-3xl font-bold text-purple-600">{dataDiaria.reduce((sum, day) => sum + day.cantidadEventos, 0)}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-4 md:p-6 rounded-lg shadow h-[400px] md:h-[500px]">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Ventas Diarias</h2>
            <div className="h-[300px] md:h-[400px]">
              <Bar data={chartDataDiaria} options={options} />
            </div>
          </div>
          <div className="bg-white p-4 md:p-6 rounded-lg shadow h-[400px] md:h-[500px]">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Ventas Mensuales</h2>
            <div className="h-[300px] md:h-[400px]">
              <Bar data={chartDataMensual} options={options} />
            </div>
          </div>
          <div className="bg-white p-4 md:p-6 rounded-lg shadow h-[400px] md:h-[500px]">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Ventas Quincenales</h2>
            <div className="h-[300px] md:h-[400px]">
              <Bar data={chartDataQuincenal} options={options} />
            </div>
          </div>
          <div className="bg-white p-4 md:p-6 rounded-lg shadow h-[400px] md:h-[500px]">
            <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Tendencia de Ventas</h2>
            <div className="h-[300px] md:h-[400px]">
              <Line data={tendenciaVentasData} options={options} />
            </div>
          </div>
        </div>

        <div className="bg-white p-4 md:p-6 rounded-lg shadow mt-8">
          <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Eventos por Vendedor (Semana {selectedWeek})</h2>
          <p className="text-sm text-gray-600 mb-4">
            Periodo: {weekDates.start?.toLocaleDateString()} - {weekDates.end?.toLocaleDateString()}
          </p>
          <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border p-2 text-left">Vendedor</th>
                  <th className="border p-2 text-left">Cantidad de Eventos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border p-2">Mariana</td>
                  <td className="border p-2">{dataVendedoras.mariana}</td>
                </tr>
                <tr>
                  <td className="border p-2">Camila</td>
                  <td className="border p-2">{dataVendedoras.camila}</td>
                </tr>
                <tr>
                  <td className="border p-2">Angel</td>
                  <td className="border p-2">{dataVendedoras.angel}</td>
                </tr>
                <tr>
                  <td className="border p-2">Otros</td>
                  <td className="border p-2">{dataVendedoras.otros}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {historialSemanal && Object.entries(historialSemanal).map(([vendedor, datos], index) => {
            const filteredEventos = selectedDate 
              ? datos.eventos.filter(evento => evento.fecha.split('T')[0] === selectedDate)
              : datos.eventos;
              
            return (
              <div key={index} className="bg-white p-4 md:p-6 rounded-lg shadow">
                <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Historial de {datos.nombre}</h2>
                <div className="mt-4 mb-4">
                  <input 
                    type="date" 
                    className="border rounded p-2 w-full"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  />
                  {selectedDate && (
                    <button 
                      className="mt-2 text-blue-500 hover:text-blue-700"
                      onClick={() => setSelectedDate('')}
                    >
                      Mostrar todos
                    </button>
                  )}
                </div>
                <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border p-2 text-left">Fecha</th>
                        <th className="border p-2 text-left">Nombre</th>
                        <th className="border p-2 text-left">Tipo</th>
                        <th className="border p-2 text-left">Evento Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredEventos.map((evento, eventoIndex) => (
                        <tr key={eventoIndex}>
                          <td className="border p-2">{new Date(evento.fecha).toLocaleDateString('es-MX', {
                            year: 'numeric',
                            month: '2-digit', 
                            day: '2-digit'
                          })}</td>
                          <td className="border p-2">{evento.nombre}</td>
                          <td className="border p-2">{evento.tipo}</td>
                          <td className="border p-2">{evento.evento}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <p className="mt-4 font-semibold">
                  Total de eventos: {filteredEventos.length}
                </p>
              </div>
            );
          })}
        </div>

        <div className="bg-white p-4 md:p-6 rounded-lg shadow mt-8">
          <h2 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">Eventos con Monto Menor que Total</h2>
          <div className="mb-4">
            <label htmlFor="filterFechaFin" className="block text-gray-700">Filtrar por FechaFin:</label>
            <select 
              id="filterFechaFin" 
              className="border rounded p-2 w-full"
              onChange={(e) => setFilterFechaFin(e.target.value)}
            >
              <option value="">Mostrar todos</option>
              <option value="Formateado">Formateado</option>
              <option value="Formulario">Formulario</option>
              <option value="Nada">Nada</option>
              <option value="Entregado">Entregado</option>
            </select>
          </div>
          <div className="overflow-x-auto" style={{ maxHeight: '50vh' }}>
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border p-2 text-left">Id</th>
                  <th className="border p-2 text-left">Nombre</th>
                  <th className="border p-2 text-left">Num Comprador</th>
                  <th className="border p-2 text-left">Tipo Evento</th>
                  <th className="border p-2 text-left">Paquete</th>
                  <th className="border p-2 text-left">Fecha Inicio</th>
                  <th className="border p-2 text-left">Fecha Fin</th>
                  <th className="border p-2 text-left">Monto</th>
                  <th className="border p-2 text-left">Detalles</th>
                  <th className="border p-2 text-left">Total</th>
                  <th className="border p-2 text-left">Socio</th>
                  <th className="border p-2 text-left">Enable Evento</th>
                </tr>
              </thead>
              <tbody>
                {eventosMontoMenorQueTotal
                  .filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin)
                  .map((evento, index) => {
                    let rowColor = '';
                    switch (evento.fechaFin) {
                      case 'Formateado':
                        rowColor = 'bg-blue-100';
                        break;
                      case 'Formulario':
                        rowColor = 'bg-yellow-100';
                        break;
                      case 'Nada':
                        rowColor = 'bg-red-100';
                        break;
                      case 'Entregado':
                        rowColor = 'bg-green-100';
                        break;
                      default:
                        rowColor = '';
                    }
                    return (
                      <tr key={index} className={rowColor}>
                        <td className="border p-2">{evento.idEvento}</td>
                        <td className="border p-2">{evento.nombre}</td>
                        <td className="border p-2">{evento.numComprador}</td>
                        <td className="border p-2">{evento.tipoEvento}</td>
                        <td className="border p-2">{evento.paquete}</td>
                        <td className="border p-2">{evento.fechaInicio}</td>
                        <td className="border p-2">{evento.fechaFin}</td>
                        <td className="border p-2">{evento.monto}</td>
                        <td className="border p-2">{evento.detalles}</td>
                        <td className="border p-2">{evento.total}</td>
                        <td className="border p-2">{evento.socio}</td>
                        <td className="border p-2">{evento.enableEvento ? 'Sí' : 'No'}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <p className="mt-4 font-semibold">
            Total de eventos: {eventosMontoMenorQueTotal.filter(evento => !filterFechaFin || evento.fechaFin === filterFechaFin).length}
          </p>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;

function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  return weekNo;
}

function getWeekDates(year, weekNumber) {
  const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  const dow = simple.getDay();
  const startDate = simple;
  if (dow <= 4)
    startDate.setDate(simple.getDate() - simple.getDay() + 1);
  else
    startDate.setDate(simple.getDate() + 8 - simple.getDay());
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 6);
  return { start: startDate, end: endDate };
}
