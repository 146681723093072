import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowDown, FaStar, FaGem, FaMedal } from "react-icons/fa6";
import { motion, useInView } from "framer-motion";

// Example images - replace with actual paths
const headerImages = [
  "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg",
  "https://i.postimg.cc/BbFjyFfF/Beauty-Flowers-Watercolor-Background-Instagram-Story.png",
  "https://i.postimg.cc/4dBPHrxR/540c053d-c4e0-448b-b058-1e9ad8030ed7.jpg",
  "https://i.postimg.cc/PrLyNVG0/c6d534a4-a351-4e69-a81a-fc1501b6ffa2.jpg",
];
const plataImage = "https://digitalrsvp.mx/api/Images/a07c01a5-e235-4cf7-ae7f-14e18189ce5d";
const oroImage = "https://digitalrsvp.mx/api/Images/32332968-0ba3-4d26-83cf-e08570c36933";
const diamanteImage = "https://i.postimg.cc/qqFGjyKm/bbaf8cf3-d40e-472f-83ac-90ff740cb3cb.jpg";

const packages = [
  {
    name: "DIAMANTE",
    tagline: "La Experiencia Definitiva",
    icon: <FaGem className="text-4xl text-[#7FE6FF] drop-shadow-[0_0_8px_rgba(127,230,255,0.6)]" />,
    imageUrl: diamanteImage,
    designs: [
      {
        preciceDec: "130",
        precice: "59.95",
        name: "Ejemplo con Fotos",
        link: "/XV-auto-diamante?idInvitado=69485",
        name2: "Ejemplo sin Fotos",
        link2: "/evento-diamante-xv-foto?name=Angel%20Jaimes",
      },
    ],
  },
  {
    name: "ORO",
    icon: <FaStar className="text-3xl text-[#FFD700]" />,
    imageUrl: oroImage,
    designs: [
      {
        preciceDec: "90",
        precice: "39.95",
        name: "Ejemplo con Fotos",
        link: "/xv-auto?id=4852",
        name2: "Ejemplo sin Fotos",
        link2: "/evento-oro-xv-fotos",
      },
    ],
  },
  {
    name: "PLATA",
    icon: <FaMedal className="text-3xl text-[#B0B0B0]" />,
    imageUrl: plataImage,
    designs: [
      {
        preciceDec: "50",
        precice: "19.95",
        name: "Ejemplo con Fotos",
        link: "/xv-auto?id=5384",
        name2: "Ejemplo sin Fotos",
        link2: "/evento-plata-xv-fotos",
      },
    ],
  },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

export const EjemplosXvEs = () => {
  
useEffect(() => {
  const script = document.createElement('script');
  script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16738412224";
  script.async = true;
  document.head.appendChild(script);

  const scriptText = document.createElement('script');
  scriptText.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-16738412224');
  `;
  document.head.appendChild(scriptText);

  return () => {
    document.head.removeChild(script);
    document.head.removeChild(scriptText);
  };
}, []);
  return (
    <div className="min-h-screen bg-[#FFE5E7] font-['Verdana']">
      <div className="relative mx-auto overflow-x-hidden">
        <div className="w-full">
          <Slider {...sliderSettings}>
            {headerImages.map((image, index) => (
              <div key={index} className="relative h-[60vh] md:h-[70vh]">
                <img
                  src={image}
                  className="h-full w-full object-cover"
                  alt=""
                />
                <div className="absolute inset-0 bg-opacity-40 flex items-center justify-center">
                  <motion.p 
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="text-2xl md:text-4xl font-['Playfair Display'] text-white text-center px-4 py-2 bg-black bg-opacity-50 rounded-lg"
                  >
                    Invitaciones para Quinceañera
                  </motion.p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <motion.div 
          className="flex flex-col md:flex-row justify-around gap-8 p-8 mt-16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {packages.map((pkg, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: "easeOut"
              }}
              whileHover={{ scale: 1.03, translateY: -5 }}
              className={`flex-1 bg-gradient-to-b from-black to-[#111] p-6 rounded-2xl transform relative shadow-lg
                ${pkg.name === "DIAMANTE" ? 
                  "border-[3px] border-[#7FE6FF] scale-105 shadow-[0_0_40px_rgba(127,230,255,0.2)] z-10" :
                pkg.name === "ORO" ? 
                  "border-[2px] border-[#FFD700]" :
                  "border-[2px] border-[#B0B0B0]"
                }`}
            >
              {pkg.name === "DIAMANTE" && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-[#7FE6FF] text-black text-xs font-bold px-4 py-1 rounded-full shadow-lg text-center">
                  MÁS POPULAR
                </div>
              )}
              
              <div className="relative pb-2 mb-3">
                <div className="flex items-center justify-center mb-2">
                  {pkg.icon}
                </div>
                <h2 className="text-lg font-['Playfair Display'] font-bold text-white text-center tracking-wider">
                  {pkg.name}
                </h2>
                {pkg.tagline && (
                  <p className="text-[#7FE6FF] text-xs text-center mt-1">
                    {pkg.tagline}
                  </p>
                )}
                <div className={`absolute bottom-0 left-0 right-0 h-[1px] ${
                  pkg.name === "DIAMANTE" ? "bg-[#7FE6FF]" :
                  pkg.name === "ORO" ? "bg-[#FFD700]" :
                  "bg-[#B0B0B0]"
                }`}></div>
              </div>

              <img
                src={pkg.imageUrl}
                alt={`${pkg.name} package`}
                className="w-full h-64 object-cover rounded-lg mb-4"
              />

              {pkg.designs.map((design, idx) => (
                <div key={idx} className="text-center">
                  <div className="mb-4 text-center">
                    <span className="text-[#FFD700] font-bold block mb-2 text-sm">
                      ¡Oferta!
                    </span>
                    <div className="flex items-center justify-center gap-2 mb-1">
                      <span className="text-[#FFD700] text-[11px]">
                        -{Math.abs(Math.round((1 - parseFloat(design.precice) / parseFloat(design.preciceDec)) * 100))}%
                      </span>
                      <span className="text-white text-lg font-bold">
                        ${design.precice}
                      </span>
                    </div>
                    <span className="text-[#B0B0B0] text-[11px]">
                      Antes <span className="line-through">${design.preciceDec}</span>
                    </span>
                  </div>

                  <div className="space-y-3">
                    <a
                      href={design.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center gap-3 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 text-white rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                    >
                      <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                      </svg>
                      <span>{design.name}</span>
                    </a>
                    
                    <a
                      href={design.link2}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center gap-3 py-2.5 px-3 bg-gradient-to-r from-gray-800 to-gray-900 text-white rounded-full hover:from-gray-700 hover:to-gray-800 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[11px] tracking-wide"
                    >
                      <svg height="20" width="20" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                        <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                      </svg>
                      <span>{design.name2}</span>
                    </a>
                  </div>
                </div>
              ))}
            </motion.div>
          ))}
        </motion.div>

        <div className="h-16"></div>
      </div>
    </div>
  );
};
